import * as React from 'react'
import { Button, type ButtonProps } from '@rouvydev/web-components/basics'
import { Spinner } from '~/components/spinner.tsx'

const spinnerVariants = {
  primary: 'dark',
  secondary: 'light',
  subtle: 'light',
  alternative: 'primary',
  danger: 'light',
}

const ActionButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { state?: 'loading' }
>((props, ref) => {
  const { state, children, variant } = props
  const loading = state === 'loading'
  const spinnerVariant = spinnerVariants[variant ?? 'primary'] as
    | 'dark'
    | 'light'
    | 'primary'
    | null
    | undefined

  return (
    <Button {...props}>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner size="xxsmall" variant={spinnerVariant} />
        </div>
      )}
      <div className={loading ? 'opacity-0' : 'opacity-100'}>{children}</div>
    </Button>
  )
})

ActionButton.displayName = 'ActionButton'

export { ActionButton }
